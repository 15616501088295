/* Container styling */
.patient-list-container {
    padding: 20px;
  }
  
  /* Styling for the link above the DataList */
  .lab-request-link {
    margin-bottom: 20px;
    text-align: right;
  }
  
  .lab-request-link a {
    display: inline-block;
    padding: 10px 15px;
    background-color: #007bff;
    color: #fff;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  .lab-request-link a:hover {
    background-color: #0056b3;
  }
  
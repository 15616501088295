/* PatientCard.css */

.patient-card {
    width: 95%;
    max-width: calc(100vw - 100px);
    margin: 20px auto;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    font-family: Arial, sans-serif;
  }
  
  .patient-card-header {
    padding: 20px;
    background-color: #f5f5f5;
    border-bottom: 1px solid #ddd;
  }
  
  .patient-card-header h2 {
    margin: 0 0 10px;
    font-size: 24px;
    color: #333;
  }
  
  .patient-card-header p {
    margin: 4px 0;
    font-size: 14px;
    color: #555;
  }
  
  .patient-card-tabs {
    background-color: #eee;
    border-bottom: 1px solid #ddd;
  }
  
  .patient-card-tabs ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
  }
  
  .patient-card-tabs li {
    flex: 1;
    text-align: center;
    padding: 12px 0;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease;
    border-right: 1px solid #ddd;
  }
  
  .patient-card-tabs li:last-child {
    border-right: none;
  }
  
  .patient-card-tabs li:hover {
    background-color: #ddd;
  }
  
  .patient-card-tabs li.active {
    background-color: #fff;
    border-bottom: 3px solid #007bff;
    color: #007bff;
  }
  
  .patient-card-content {
    padding: 20px;
  }
  
/* LabTestReport.css */

.lab-test-report {
  font-family: 'Arial', sans-serif;
  color: #333;
  margin: 20px;
  padding: 20px;
  background-color: #fff;
}

/* The .printable class is used to constrain the printable content and avoid page breaks inside the report */
.lab-test-report.printable {
  display: block;
}
.printable {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  page-break-inside: avoid;
}

.lab-test-report {
  font-family: Arial, sans-serif;
  color: #333;
  margin: 10px;
  padding: 10px;
  background-color: #fff;
}

.report-header {
  display: flex;
  align-items: center;
  border-bottom: 2px solid #333;
  padding-bottom: 5px;
  margin-bottom: 10px;
}

.lab-logo img {
  width: 150px;
  height: auto;
  margin-right: 10px;
}

.header-compact {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.lab-info, .patient-compact, .report-meta {
  margin: 0 10px;
  font-size: 0.9em;
}

.results-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.results-table th,
.results-table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

.results-table th {
  background-color: #f5f5f5;
}

.report-footer {
  border-top: 2px solid #333;
  text-align: center;
  padding-top: 10px;
  font-size: 0.9em;
  color: #666;
  margin-top: 20px;
}

.signature-line {
  margin-top: 15px;
  font-style: italic;
}

.cbc-section {
  margin-top: 30px;
  padding-top: 10px;
  border-top: 2px solid #333;
  page-break-before: always;
}

.cbc-section h2 {
  margin-bottom: 15px;
  font-size: 1.5em;
  color: #007bff;
}

/* Optionally, add a special style for the CBC table */
.cbc-table th,
.cbc-table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

.cbc-table th {
  background-color: #f0f8ff;
}

.cbc-table td {
  background-color: #fff;
}

.test-category-section {
  margin-top: 20px;
  padding-top: 10px;
  border-top: 1px solid #999;
}

.test-category-header {
  margin-bottom: 10px;
  font-size: 1.25em;
  color: #333;
}

.test-type-section {
  margin-bottom: 20px;
}

.test-type-header {
  margin-bottom: 8px;
  font-size: 1.1em;
  color: #555;
}

/* CBC section styling (unchanged from before) */
.cbc-section {
  margin-top: 30px;
  padding-top: 10px;
  border-top: 2px solid #333;
}

/* Print-specific rules */
@media print {
  /* Remove margins, padding, and shadows that are not needed for print */
  .lab-test-report {
    margin: 0;
    padding: 0;
    box-shadow: none;
  }
  
  /* The printable content should take full width in print */
  .printable {
    width: 100%;
    max-width: none;
    margin: 0;
  }
  
  /* Optionally, hide interactive elements (buttons, links, navigation, etc.) */
  .no-print, .action-buttons {
    display: none !important;
  }
  
  /* Hide everything on the page except the report content */
  body * {
    visibility: hidden;
  }
  
  /* Make sure the report content is visible and positioned correctly */
  .lab-test-report,
  .lab-test-report * {
    visibility: visible;
  }
  
  .lab-test-report {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
}
.action-buttons {
  text-align: right;
  margin-bottom: 10px;
}

.action-buttons button {
  margin-left: 10px;
  padding: 8px 16px;
  font-size: 0.9em;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn-print {
  background-color: #4caf50;
  color: #fff;
}

.btn-download {
  background-color: #2196f3;
  color: #fff;
}

.btn-print:hover,
.btn-download:hover {
  opacity: 0.9;
}
/* PDF generation mode: Add bottom margin and avoid breaking table rows */
.pdf-mode {
  margin-bottom: 20mm; /* narrow bottom margin */
  margin-top:20mm;
}

.pdf-mode table tr {
  page-break-inside: avoid;
}

.pdf-mode .lab-test-report .cbc-section {
  page-break-before:always;
}

.cbc-section {
  page-break-before: always;
}
.medical-lab-widget {
    font-family: Arial, sans-serif;
    padding: 20px;
  }
  
  .header {
    background-color: #f0f0f0;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
  }
  
  .header-info span {
    display: block;
  }
  
  .header-buttons button {
    margin: 5px 0;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .category {
    margin-bottom: 20px;
  }
  
  .category h3 {
    background-color: #ddd;
    padding: 10px;
    margin: 0;
  }
  
  .types-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); /* Responsive columns */
    gap: 20px; /* Spacing between cards */
    justify-content: center; /* Center the cards when there's extra space */
    padding: 5px;
  }
  
  .type {
    max-width: 250px; /* Set a maximum width for each type card */
    width: 100%; /* Ensure cards fill the available space up to max-width */
    background-color: #b0c6f0;
    padding: 5px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional shadow for better UI */
  }
  
  .type h4 {
    margin: 0 0 10px 0;
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }
  
  .tests {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .test-checkbox-label {
    display: flex;
    align-items: center;
    gap: 5px;
    background-color: #f0f0f0;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .test-checkbox-label input {
    margin-right: 5px;
  }
  
  .test-checkbox-label:hover {
    background-color: #e0e0e0;
  }
  
  .header-buttons {
    display: flex;
    gap: 10px; /* Space between buttons */
  }
  
  .header-buttons button {
    background-color: #007bff; /* Primary blue color */
    color: #fff; /* White text */
    border: none;
    border-radius: 5px; /* Rounded corners */
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Subtle shadow */
  }
  
  .header-buttons button:hover {
    background-color: #0056b3; /* Darker blue on hover */
    transform: translateY(-2px); /* Slight lift on hover */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3); /* Enhanced shadow on hover */
  }
  
  .header-buttons button:active {
    background-color: #003d80; /* Even darker blue on click */
    transform: translateY(0); /* Reset lift on click */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Reset shadow on click */
  }
  .patient-info {
    background-color: #f9f9f9; /* Light background for contrast */
    border: 1px solid #ccc; /* Subtle border for structure */
    padding: 10px;
    border-radius: 8px; /* Rounded corners */
    min-width: 300px;
    max-width: 500px; /* Limit the width for readability */
    margin-bottom: 10px; /* Space below the patient section */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  }
  
  .patient-info-row {
    display: flex;
    justify-content: space-between; /* Align labels and values */
    align-items:start;
    margin-bottom: 0px; /* Space between rows */
    font-size: 16px; /* Comfortable font size */
    line-height: 1.5; /* Increase line height for readability */
  }
  
  .patient-info-row strong {
    color: #333; /* Darker text color for labels */
    margin-right: 40px;
}
  
  .patient-info-row span {
    color: #555; /* Slightly lighter text for values */
    text-align: left;
  }  

  /* Branch input container */
.branch-input-container {
  margin: 1rem 0;
  display: flex;
  align-items: center;
}

.branch-input-container label {
  font-weight: bold;
  margin-right: 0.5rem;
}

.branch-input-container select {
  flex: 0 0 200px;
  padding: 0.4rem;
  font-size: 1rem;
}

.tab-navigation {
  margin: 10px 0;
  text-align: center;
}

.tab-navigation button {
  margin: 0 5px;
  padding: 8px 16px;
  border: none;
  background-color: #ddd;
  cursor: pointer;
  font-size: 1em;
}

.tab-navigation button.active-tab {
  background-color: #4caf50;
  color: #fff;
}
/* Container for the attribute group */
.attribute-group {
  display: flex;
  align-items: center;
  flex-wrap: wrap; /* Allow wrapping if there are too many checkboxes */
  margin-bottom: 5px;
  background-color: #f0f0f0;
}

/* Style for each checkbox label */
.test-checkbox-label {
  display: flex;
  align-items: center;
  margin-right: 5px; /* Space between checkboxes */
  font-size: 12px;
}

/* Style for the test name span */
.attribute-group > span {
  margin-left: 5px; /* Space between the last checkbox and the test name */
  font-weight: bold;
}

.attr-checkbox-label {
  display: flex;
  align-items: center;
  margin-right: 5px; /* Space between checkboxes */
  font-size: 12px;
  padding: 0;
}
.attr-test-name {
  font-size: 14px;
}
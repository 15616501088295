.lab-test-request-detail-form {
  font-family: Arial, sans-serif;
  padding: 20px;
}

.request-header {
  margin-bottom: 20px;
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.category-group {
  margin-bottom: 30px;
}

.category-group h3 {
  background-color: #f0f0f0;
  padding: 10px;
  margin: 0 0 10px;
  border-radius: 5px;
  text-align: center;
}

.type-group-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Responsive grid for type groups */
  gap: 20px;
}

.type-group {
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  background-color: #fff;
  max-width: 400px;

}

.type-group h4 {
  margin: 0 0 10px;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  text-align: center;
  border-bottom: 1px solid #eee;
  padding-bottom: 5px;

}

.test-row {
  display: flex;
  justify-content:flex-start; /* Ensure label and input stay aligned */
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;

}

.test-name {
  font-size: 14px;
  color: #333;
  flex: 1; /* Make the label take up available space */
  width: calc((100%-120px ) / 4);
}

.test-result-input {
  width: 200px; /* Fixed width for input box */
  padding: 5px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.btn-save,
.btn-save-complete {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn-save {
  background-color: #007bff;
}

.btn-save:hover {
  background-color: #0056b3;
}

.btn-save-complete {
  background-color: #28a745;
}

.btn-save-complete:hover {
  background-color: #1e7e34;
}
/* Styles for the loading indicator */
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px;  /* Adjust as needed */
  padding: 20px;
  text-align: center;
  color: #333;
}

.spinner {
  border: 8px solid #f3f3f3;        /* Light gray background */
  border-top: 8px solid #007bff;      /* Blue color for the spinner */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
  margin-bottom: 20px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-text {
  font-size: 1.2em;
  color: #555;
}
/* Progress Bar Container */
.progress-bar {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
  margin-top: 10px;
}

/* Progress Bar Fill */
.progress-bar-fill {
  height: 20px;
  background-color: #007bff;
  text-align: center;
  line-height: 20px;
  color: #fff;
  transition: width 0.3s ease;
}

.result-note-icon {
  margin-left: 8px;
  cursor: pointer;
  font-size: 1.2em;
  vertical-align: middle;
  color: #007bff;
}

.result-note-icon:hover {
  color: #0056b3;
}

.result-note-text {
  display: block;
  width: 90%;
  min-height:auto;
  margin: 5px 0 0 0;
  padding: 5px;
  font-size: 0.9em;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical; 
  overflow: auto; 
}

/* Findings/Recommendations Section */
.findings-container {
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f9f9f9;
}

.findings-container label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.findings-container textarea {
  width: 100%;
  min-height: 80px;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  resize: vertical;
}
.advanced-array-input {
    margin: 10px 0;
  }
  
  .advanced-array-input label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  .input-group {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .advanced-array-input .input-group input {
    flex: 1;
    padding: 5px;
    margin-right: 10px;
    font-size: large !important;
  }
  
  .advanced-array-input  .input-group button {
    padding: 5px 10px;
  }
  
  .advanced-array-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 12px;
  }

  .nameCol {
    width: 40%;
  }

  
  
  thead {
    background-color: #f4f4f4;
  }
  
  .advanced-array-input .advanced-array-table th,
  .advanced-array-table td {
    border: 1px solid #ccc;
    padding: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  /* Ensure the input elements fill the width of the cell */
  .form-group-horizontal .advanced-array-input .advanced-array-table td input {
    width: 100%;
    box-sizing: border-box;
    font-size: 12px;
  }
  
  .advanced-array-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .advanced-array-table tbody tr:hover {
    background-color: #f1f1f1;
  }
  
  .advanced-array-table button {
    padding: 5px 5px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    font-size: small;
  }
  
  .advanced-array-table button:hover {
    background-color: #0056b3;
  }


 .missing-item-id-warning {
    color: red;
  }
.view-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 60%;
  max-height: 90%;
  overflow-y: auto;
  padding: 20px;
}

.view-modal-content {
  display: flex;
  flex-direction: column;
}

.view-modal h2 {
  margin-bottom: 10px;
  text-align: center;
  font-size: 1.8rem;
  color: #333;
}

.view-modal-section {
  margin-bottom: 10px;
}

.view-modal-section h3 {
  margin-bottom: 15px;
  font-size: 1.5rem;
  color: #444;
  text-align: left;
  border-bottom: 2px solid #007bff; /* Makes the header pop */
  padding-bottom: 5px;
}

.view-modal-details {
  display: flex;
  flex-direction: column;
}

.view-modal-field {
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 0px;
  background-color: #f9f9f9; 
}

.view-modal-field strong {
  background-color: #959799; 
  color: white;
  padding: 10px;
  margin-right: 10px;
  border-radius: 3px;
}

.view-modal-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 10px;
}

.view-modal-edit-button,
.view-modal-print-button,
.view-modal-close-button {
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.view-modal-edit-button {
  background-color: #28a745;
  color: white;
}

.view-modal-edit-button:hover {
  background-color: #218838;
}

.view-modal-print-button {
  background-color: #007bff;
  color: white;
}

.view-modal-print-button:hover {
  background-color: #0056b3;
}

.view-modal-close-button {
  background-color: #dc3545;
  color: white;
}

.view-modal-close-button:hover {
  background-color: #c82333;
}

.printable {
  display: none;
}



@media print {
  .view-modal {
    @page {
      size: A4 portrait;
      margin: 10mm;
    }
  }


  body {
    margin: 0;
    padding: 0;
    font-size: 12pt;
  }

  span {
    white-space: normal;
  }
  /* Ensure the modal content fits into an A4 page */
  .view-modal {
position: absolute; /* Remove fixed positioning */
top:30px;
left:0px;
transform: none;  /* Remove centering transform */
width: 100%;      /* Make sure it spans the page width */
max-height: none; /* Allow content to flow naturally */
overflow: visible; /* Remove scrolling behavior */
padding: 10mm;     /* Remove padding for printing, adjust if needed */
box-shadow: none; /* Remove box-shadow for print */
border-radius: 0; /* Remove border-radius for printing */

  }

 /* Ensure content is visible and formatted correctly */
 .view-modal-content {
 /* page-break-inside: avoid;  Avoid page breaks inside content */
  margin: 0; /* Remove any margin to make content fit within the page */
}

/* Ensure no unnecessary space before the modal content */
.view-modal * {
  margin: 0; /* Remove any margin from inside the modal */
  padding: 0; /* Remove any padding from inside the modal */
}

.view-modal-section {
  page-break-inside: avoid; /* Force page breaks after each section */
  padding: 10mm;
}
  /* Ensure visibility for printing */
  body * {
    visibility: hidden;
  }

  .view-modal * {
    visibility: visible;
  }

  /* Hide unnecessary buttons for printing */
  .view-modal-print-button {
    display: none;
  }
  
  .view-modal-close-button {
    display: none;
  }
  
  .view-modal-edit-button {
    display: none;
  }

}
/* Modal container styling */
.patient-bill-form {
  background-color: #fff;
  border-radius: 8px;
  padding: 10px;
  width: 80%;
  max-width: 900px;
  margin: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  max-height: 90vh; /* Set a maximum height */
  overflow-y: auto; /* Enable vertical scrolling */
}

/* Modal header */
.modal-header {
  position: relative; /* Make it a positioning context */
  display: flex;
  justify-content: space-between;
  align-items: start;
  border-bottom: 1px solid #ddd;
  padding: 5px;
  margin-bottom: 5px;
}

.modal-header h2 {
  margin: 0;
  font-size: 1.4em;
}

.modal-close {
  position: absolute;
  top: 5px;
  right: 5px;
  background: transparent;
  border: none;
  font-size: 1.8em;
  line-height: 1;
  cursor: pointer;
  color: #999;
}
.modal-close:hover {
  color: #333;
}

/* Existing bill info styling */
.existing-bill-info {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 10px;
  font-size: 0.9em;
  background-color: #c3c8cf;
  padding: 5px;
  border: 1px solid #eee;
  border-radius: 4px;
}

.existing-bill-info p {
  flex: 1 1 45%; /* Adjust the width of each item */
  margin: 5px 0;
}

.existing-bill-info p:nth-child(odd) {
  margin-right: 10%;
}
.existing-bill-info .excludes-fees {
  flex-basis: 100%;
  text-align: center;
  font-size: 0.8em;
  color: #999;
  padding-top: 0px;
  margin-top: 0px;
}
/* Section headers */
.patient-bill-form h3 {
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
  padding: 5px;
  font-size: 1.0em;
  background-color: rgb(136, 173, 236);
}

/* List styling */
.patient-bill-form ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.patient-bill-form li {
  margin-bottom: 0px;
  padding: 3px 4px;
  border: 1px solid #eee;
  border-radius: 4px;
  font-size: 0.9em;
}

.patient-bill-form .child-details {
  list-style-type: disc;
  margin-left: 20px;
  margin-top: 4px;
  font-size: 0.85em;
}

/* Invoice actions */
.invoice-actions {
  text-align: center;
  margin-top: 20px;
}

.invoice-actions button {
  padding: 10px 20px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
}

.invoice-actions button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Invoice result styling */
.invoice-result {
  text-align: center;
  margin-top: 15px;
  font-size: 1.1em;
  color: #333;
}

/* Responsive design */
@media (max-width: 600px) {
  .patient-bill-form {
    width: 90%;
    padding: 15px;
  }
}

.selected-icon {
  font-size: 1.2em;
  color: green;
  margin-right: 5px;
}

.highlight {
  background-color: #f9f9f9;
  color: red;
}

.excludes-fees {
  font-size: 0.8em;
  color: #999;
  padding-top: 0px;
  margin-top: 0px;
}
.estimated-fees {
  font-size: 1.0em;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

/* Align Company and Careoff Name on the same row */
.bill-section .form-group.horizontal-group {
  display: flex;
  flex-direction: row; /* Align items horizontally */
  align-items:center; /* Vertically align items */
  margin: 2px;
  gap: 15px; /* Add spacing between the dropdown and input */
}


.bill-section .form-group.horizontal-group select,
.bill-section .form-group.horizontal-group input {
  flex: 1; /* Allow elements to share available space */
  padding: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 0.75em;
  box-sizing: border-box; /* Ensure padding doesn't affect width */
  min-width: 200px; /* Set a minimum width for inputs */
}


.bill-section .form-group.horizontal-group label {
  flex: 0 0 auto; /* Prevent the label from stretching */
  font-weight: bold;
  font-size: 0.75em;
  white-space: nowrap; /* Prevent labels from wrapping to the next line */
}

.bill-section .form-group select,
.bill-section .form-group label 
{
  margin-top: 5px;
  width:100px;
  font-size: 0.75em;
  padding: 4px;
}

.bill-section  .requiredLabel 
{
  color: red;
}

.bill-section .form-group.horizontal-group > div {
  display: flex;
  flex-direction: row; /* Align label and field horizontally */
  align-items: center; /* Vertically align label and field */
  gap: 5px; /* Add spacing between label and field */
}